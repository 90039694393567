import axios from "~/plugins/axios";
export default {
  // tco
  getProduct() {
    return axios
      .get("/ebsn/api/tco/tco_product_view", {})
      .then(data => {
        return data.data.data;
      })
      .catch(err => err);
  },
  setProduct(productId) {
    return axios
      .get("/ebsn/api/tco/tco_product_add", {
        params: {
          product_id: productId
        }
      })
      .then(data => {
        return data.data.data;
      })
      .catch(err => err);
  },
  getDetail() {
    return axios
      .get("/ebsn/api/tco/tco_detail", {})
      .then(data => {
        return data.data.data;
      })
      .catch(err => err);
  },

  // tigros-tco-bar
  getBarViewDetails() {
    return axios
      .get("/ebsn/api/tigros-tco-bar/bar_view_details", {})
      .then(data => {
        return data.data.data;
      })
      .catch(err => err);
  },

  // tigros-digital-tco
  getShowItemMenu() {
    return axios
      .get("/ebsn/api/tigros-digital-tco/show_item_menu", {})
      .then(data => {
        return data.data.data;
      })
      .catch(err => err);
  },
  forceSelectTypeCardTco(digitalTypeCard) {
    let url = "/ebsn/api/tigros-digital-tco/force_select_type_card_tco";
    let param = {
      digital_type_card: digitalTypeCard
    };
    return axios
      .post(url, param, {})
      .then(response => {
        return response.data.data;
      })
      .catch(err => {
        console.log(err);
      });
  },
  selectTypeCardTco(digitalTypeCard) {
    let url = "/ebsn/api/tigros-digital-tco/select_type_card_tco";
    let params = {
      digital_type_card: digitalTypeCard
    };
    return axios
      .post(url, null, { params })
      .then(response => {
        return response.data.data;
      })
      .catch(err => {
        console.log(err);
      });
  }
};
