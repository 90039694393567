<template>
  <v-card flat class="welcome-message-card">
    <v-row>
      <v-col cols="12" sm="7">
        <div
          class="d-flex flex-column justify-center text-center text-sm-left h-100 primary--text"
        >
          <v-card-title
            v-if="$te(textPath + '.title')"
            v-html="$t(textPath + '.title')"
          ></v-card-title>
          <v-card-text class="primary--text">
            <p
              class="mr-md-4"
              v-if="$te(textPath + '.subtitle')"
              v-html="$t(textPath + '.subtitle')"
            ></p>
            <p
              class="mr-md-4"
              v-if="$te(textPath + '.content')"
              v-html="$t(textPath + '.content')"
            ></p>
          </v-card-text>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <v-img
          height="100%"
          src="/img_layout/reserved_area/tco_perks/welcome-card-image.png"
          alt="Immagine carta oro"
          contain
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss">
.welcome-message-card {
  background-image: linear-gradient(
    270deg,
    #ffd83a,
    rgba(245, 240, 197, 0.1576),
    rgba(254, 248, 205, 0.8356),
    #ffd83a
  );
  .v-card__title {
    font-size: 50px;
    line-height: 1em;
    margin-bottom: 30px;
    font-family: $heading-font-family;
  }
  .v-card__text {
    font-size: 20px;
  }
}
</style>
<script>
export default {
  name: "WelcomeMessage",
  data() {
    return {};
  },
  props: {
    textPath: { type: String, required: true }
  }
};
</script>
