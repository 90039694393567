<template>
  <v-card
    flat
    outlined
    class="tco-progress-card primary--text"
    v-if="barViewDetails && barViewDetails.show_bar"
  >
    <v-card-title class="text-center">
      {{ $t("tco.perks.tcoProgress.title", { year: barViewDetails.year + 1 }) }}
    </v-card-title>
    <v-card-text class="primary--text">
      <div
        class="d-flex flex-column flex-md-row align-md-baseline align-center justify-center"
      >
        <span class="perc-text primary--text d-block"
          >{{ barViewDetails.perc }}%</span
        >
        <div class="progress-bar-container w-100">
          <v-progress-linear
            :value="barViewDetails.perc"
            color="white"
            height="38"
          >
            <v-img
              class="card-img-bar"
              width="33px"
              height="21px"
              src="/img_layout/reserved_area/tco_perks/tco-cart.svg"
              alt="Immagine carta oro"
              :style="styleCalculated"
            />
          </v-progress-linear>
          <div
            class="box arrow-top"
            v-if="barViewDetails.message"
            :style="styleCalculated"
          >
            <!-- ng-style="{'color': '{{vm.tcoProgress.message.color_title}}'}" -->
            <span
              class="d-block box-title"
              v-if="barViewDetails.message.title"
              :style="'color: ' + barViewDetails.message.color_title"
              >{{ barViewDetails.message.title }}</span
            >
            <span
              class="box-descr font-weight-bold"
              v-if="barViewDetails.message.message"
              >{{ barViewDetails.message.message }}</span
            >
          </div>
        </div>
        <img
          width="140px"
          height="120px"
          src="/img_layout/reserved_area/tco_perks/tigros-card-oro.png"
          alt="Immagine carta oro"
        />
      </div>
    </v-card-text>
  </v-card>
</template>
<style lang="scss">
.tco-progress-card {
  .v-card__title {
    font-family: $heading-font-family;
    font-size: 20px;
  }
  .v-card__text {
    .perc-text {
      font-size: 28px;
      font-weight: bold;
      margin-right: 20px;
      align-self: flex-start;
      margin-top: 42px;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        margin-top: 20px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        margin-bottom: 20px;
      }
    }
    .v-progress-linear {
      text-align: right;
    }
    .v-progress-linear__buffer {
      border-radius: 18px;
      border: 1px solid #c99740;
    }
    .v-progress-linear__determinate {
      border-radius: 18px;
      max-width: 99%;
      margin: 3px 4px;
      height: 32px;
      background-color: #c99740;
      background-image: linear-gradient(to right, #ffdf65, #c99740);
    }
    .v-progress-linear__content {
      position: relative;
      width: 100%;
      .card-img-bar {
        position: absolute;
      }
    }
    .box {
      position: relative;
      height: auto;
      width: 275px;
      background-color: white;
      padding: 15px 20px;
      border-radius: 20px;
      margin-top: 15px;
      border: 1px solid $gold;
      box-shadow: 0 0 20px #ccc;
      transform: translateX(-30%);
      &.arrow-top:after {
        content: " ";
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: white;
        border-left: 1px solid $gold;
        border-top: 1px solid $gold;
        border-right: 0px;
        border-bottom: 0px;
        transform: rotate(45deg);
        top: 0%;
        left: 33%;
        margin-top: -8px;
      }
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        width: auto;
        left: auto !important;
        transform: translateX(0%);
        &.arrow-top:after {
          display: none;
        }
      }
      .box-title {
        font-weight: bold;
        font-size: 16px;
      }
      .box-descr {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}
</style>
<script>
import TigrosCardOroService from "@/service/tigrosCardOroService";

export default {
  name: "TcoProgress",
  data() {
    return {
      barViewDetails: null
    };
  },
  computed: {
    styleCalculated() {
      return this.barViewDetails && this.barViewDetails.perc > 6
        ? `left: ${this.barViewDetails.perc - 6}%`
        : "left: 0%";
    }
  },
  methods: {
    async getBarViewDetails() {
      let res = await TigrosCardOroService.getBarViewDetails();
      if (res && res.value) this.barViewDetails = res.value;

      // simulate barViewDetails
      // this.barViewDetails = {
      //   show_bar: true,
      //   year: 2021,
      //   fidelity_card: "0410777008992",
      //   // perc: 75,
      //   // message: {
      //   //   title: "Continua cosi! ",
      //   //   color_title: "#00BA1F",
      //   //   message:
      //   //     "Fai sempre la tua spesa da TIGROS per mantenere i vantaggi Carta Oro anche il prossimo anno"
      //   // }
      //   perc: 100,
      //   message: {
      //     title: null,
      //     color_title: null,
      //     message:
      //       "Complimenti hai raggiunto il traguardo! Puoi usufruire dei tuoi vantaggi anche il prossimo anno! Grazie per la fiducia che ci hai accordato"
      //   }
      // };
    }
  },
  mounted() {
    this.getBarViewDetails();
  }
};
</script>
