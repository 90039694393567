<template>
  <v-card
    flat
    outlined
    class="tco-block-card primary--text h-100 text-center d-flex flex-column align-center justify-space-between"
  >
    <div v-if="active" class="avaiable-banner">
      {{ $t("tco.perks.blocks.availableNow") }}
    </div>
    <v-img
      v-if="srcImage"
      :src="srcImage"
      alt="Immagine tco card"
      max-width="250"
      max-height="250"
      contain
    />

    <v-card-title
      class="text-center text-h2 line-height-13"
      v-if="tcoBlock.title.rendered"
      v-html="tcoBlock.title.rendered"
    >
    </v-card-title>
    <v-card-text
      class="primary--text text-body-1"
      v-if="tcoBlock.excerpt.rendered"
      v-html="tcoBlock.excerpt.rendered"
    >
    </v-card-text>
    <v-card-actions>
      <v-btn color="secondary" depressed @click="modalOpen = true">
        {{ $t("tco.perks.blocks.openModalBtn") }}
      </v-btn>
    </v-card-actions>
    <div>
      <v-dialog v-model="modalOpen" max-width="700">
        <!-- <div class="vuedl-layout__closeBtn" @click="modalOpen = false">
        ×
      </div> -->
        <v-card>
          <div class="d-flex tco-dialog">
            <v-img
              v-if="srcImage"
              :src="srcImage"
              alt="Immagine tco card"
              max-width="250"
              contain
            />
            <div>
              <v-card-title
                class="text-center text-h2 primary--text"
                v-if="tcoBlock.title.rendered"
                v-html="tcoBlock.title.rendered"
              >
              </v-card-title>
              <v-card-text
                class="primary--text text-body-1"
                v-if="tcoBlock.content.rendered"
                v-html="tcoBlock.content.rendered"
              >
              </v-card-text>
            </div>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" @click="modalOpen = false">
              Chiudi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>
<style scoped lang="scss">
@media #{map-get($display-breakpoints, 'xs-only')} {
  .tco-dialog {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

.tco-block-card {
  .avaiable-banner {
    position: absolute;
    font-size: 18px;
    font-weight: bold;
    padding: 5px 10px;
    top: 0;
    right: 0;
    z-index: 1;
    background-image: linear-gradient(270deg, #fbb61f, #ffd83a, #fbf7d6);
  }
}
</style>
<script>
import get from "lodash/get";

export default {
  name: "TcoBlock",
  data() {
    return {
      modalOpen: false
    };
  },
  props: {
    tcoBlock: { type: Object, required: true },
    active: { type: Boolean, default: false }
  },
  computed: {
    srcImage() {
      return get(this.tcoBlock, "yoast_head_json.og_image[0].url");
    }
  }
};
</script>
