<template>
  <div class="tco-cards-container mt-8" v-if="tcoCards && tcoCards.length">
    <v-row>
      <v-col
        cols="12"
        :lg="getColSize(index)"
        v-for="(tcoCard, index) in tcoCards"
        :key="tcoCard.id"
      >
        <v-card
          min-height="320"
          :img="srcImage(tcoCard)"
          class="tco-card h-100"
        >
          <span
            class="primary white--text py-3 px-4 px-md-6 text-uppercase font-weight-bold new-badge rounded-tl-0"
          >
            {{ $t("tco.perks.cards.newBadge") }}
          </span>
          <div
            class="d-flex flex-column flex-lg-row align-center justify-center h-100"
          >
            <img
              v-if="tcoCard?.image?.guid"
              :src="tcoCard.image.guid"
              class="tco-card-img mx-auto d-block my-6 px-4 pt-2 pt-sm-0"
            />
            <div class="py-3">
              <v-card-title
                v-if="tcoCard.title.rendered"
                v-html="tcoCard.title.rendered"
                class="text-h2 primary--text tco-card-title"
              ></v-card-title>
              <v-card-text
                v-if="tcoCard.summary"
                v-html="tcoCard.summary"
                class="primary--text font-weight-bold"
              ></v-card-text>
              <v-card-actions>
                <v-btn
                  color="secondary"
                  depressed
                  @click="openModal(tcoCard.id)"
                >
                  {{ $t("tco.perks.cards.openModalBtn") }}
                </v-btn>
              </v-card-actions>
            </div>
          </div>
          <v-dialog v-model="modalOpen[tcoCard.id]" max-width="700">
            <v-card class="px-5 pt-5">
              <div
                class="d-flex flex-column flex-md-row align-center justify-center"
              >
                <v-img
                  v-if="srcImage"
                  :src="tcoCard.image.guid"
                  alt="Immagine tco card"
                  max-width="250"
                  contain
                />
                <div>
                  <v-card-title
                    class="text-h2 primary--text"
                    v-if="tcoCard.title.rendered"
                    v-html="tcoCard.title.rendered"
                  >
                  </v-card-title>
                  <v-card-text
                    class="primary--text text-body-1"
                    v-if="tcoCard.content.rendered"
                    v-html="tcoCard.content.rendered"
                  >
                  </v-card-text>
                </div>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  color="primary"
                  @click="modalOpen[tcoCard.id] = false"
                >
                  {{ $t("tco.perks.cards.closeModalBtn") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss" scoped>
.tco-cards-container {
  .tco-card {
    .tco-card-img {
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        max-width: 150px;
      }
      max-width: 33.33%;
    }
    .tco-card-title {
      line-height: inherit !important;
    }
    .new-badge {
      position: absolute;
      top: 0;
      right: 0;
      letter-spacing: 1px;
    }
  }
}
</style>
<script>
import cmService from "~/service/cmService";
import get from "lodash/get";
import clickHandler from "@/commons/mixins/clickHandler";

export default {
  name: "TcoCards",
  data() {
    return {
      tcoCards: null,
      modalOpen: {} // Change modalOpen to an object
    };
  },
  mixins: [clickHandler],
  methods: {
    async getTcoCards() {
      this.tcoCards = await cmService.getCustomPostBySlug(
        "tcocard",
        this.$route.params.slug
      );
      // Initialize modalOpen state for each card
      this.tcoCards.forEach(card => {
        this.$set(this.modalOpen, card.id, false);
      });
    },
    srcImage(tcoCard) {
      return get(tcoCard, "yoast_head_json.og_image[0].url");
    },
    openModal(cardId) {
      // Open modal for the specific card
      this.$set(this.modalOpen, cardId, true);
    },
    getColSize(index) {
      if (this.tcoCards.length === 1) {
        return 12;
      } else if (this.tcoCards.length % 2 === 0) {
        return 6;
      } else {
        if (index === 0) {
          return 12;
        } else {
          return 6;
        }
      }
    }
  },
  async mounted() {
    await this.getTcoCards();
  }
};
</script>
