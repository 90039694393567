<template>
  <div
    class="tco-blocks-contaier mt-8"
    v-if="tcoBlocks && tcoBlocks.length > 0"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-if="getBlockBySlug('per-il-tuo-compleanno')"
      >
        <TcoBlock
          :tcoBlock="getBlockBySlug('per-il-tuo-compleanno')"
          :active="promoCake"
        />
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <TcoOroProductBlock />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        v-if="getBlockBySlug('per-le-tue-feste-di-natale-e-pasqua')"
      >
        <TcoBlock
          :tcoBlock="getBlockBySlug('per-le-tue-feste-di-natale-e-pasqua')"
          :active="promoPanettoni"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        v-if="getBlockBySlug('per-sorprenderti-quando-meno-te-lo-aspetti')"
      >
        <TcoBlock
          :tcoBlock="
            getBlockBySlug('per-sorprenderti-quando-meno-te-lo-aspetti')
          "
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        v-if="getBlockBySlug('per-unextra-convenienza')"
      >
        <TcoBlock
          :tcoBlock="getBlockBySlug('per-unextra-convenienza')"
          :active="promoBolloni"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        v-if="getBlockBySlug('per-la-tua-spesa-online')"
      >
        <TcoBlock :tcoBlock="getBlockBySlug('per-la-tua-spesa-online')" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TcoBlock from "@/components/cardOro/TcoBlock.vue";
import TcoOroProductBlock from "@/components/cardOro/TcoOroProductBlock.vue";

import recaptcha from "~/mixins/recaptcha";

import TigrosCardOroService from "@/service/tigrosCardOroService";
import CmService from "~/service/cmService";

import find from "lodash/find";

export default {
  name: "TcoBlocks",
  components: { TcoBlock, TcoOroProductBlock },
  mixins: [recaptcha],
  data() {
    return {
      tcoBlocks: null,
      crmUserSegments: null,
      promoCake: null,
      promoPanettoni: null,
      promoBolloni: null,
      promoVar: [
        { name: "cake", cod: "promovar_80001" },
        { name: "panettoni", cod: "promovar_80002" },
        { name: "gift", cod: "promovar_80003" },
        { name: "bolloni", cod: "promovar_9801" },
        { name: "delivery", cod: "promovar_80004" }
      ]
    };
  },
  computed: {},
  methods: {
    getBlockBySlug(slug) {
      return find(this.tcoBlocks, block => block.slug == slug);
    },
    async getTcoBlocks() {
      let res = await CmService.getCustomPostByFilters("posts", [
        {
          filterName: "filter[taxonomy]",
          filterValue: "category"
        },
        {
          filterName: "filter[term]",
          filterValue: "carta oro"
        }
      ]);
      if (res) this.tcoBlocks = res.data;
    },
    async getDetail() {
      let _this = this;
      let res = await TigrosCardOroService.getDetail();
      _this.crmUserSegments = res.crmUserSegments;
      _this.promoVar.forEach(p => {
        if (_this.crmUserSegments[p.cod]) {
          if (p.name == "cake") {
            _this.promoCake = _this.crmUserSegments[p.cod].value ? true : false;
          }
          if (p.name == "panettoni") {
            _this.promoPanettoni = _this.crmUserSegments[p.cod].value
              ? true
              : false;
          }
          if (p.name == "gift") {
            // _this.promoGift=_this.crmUserSegments[p.cod].value;
          }
          if (p.name == "bolloni") {
            _this.promoBolloni = _this.crmUserSegments[p.cod].value
              ? true
              : false;
          }
          if (p.name == "delivery") {
            // _this.promoDelivery=_this.crmUserSegments[p.cod].value;
          }
        }
      });
    }
  },
  async mounted() {
    await this.getTcoBlocks();
    await this.getDetail();
  }
};
</script>
