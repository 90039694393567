<template>
  <div class="mt-5 tco-perks-container">
    <WelcomeMessage :textPath="'tco.perks.welcomeBanner'" />

    <h1 class="text-center my-4">{{ $t("tco.perks.title") }}</h1>

    <TcoProgress />
    <TcoCards />
    <TcoBlocks />
  </div>
</template>
<script>
import WelcomeMessage from "@/components/cardOro/WelcomeMessage.vue";
import TcoProgress from "@/components/cardOro/TcoProgress.vue";
import TcoBlocks from "@/components/cardOro/TcoBlocks.vue";
import TcoCards from "@/components/cardOro/TcoCards.vue";

export default {
  name: "TcoPerks",
  components: {
    WelcomeMessage,
    TcoProgress,
    TcoBlocks,
    TcoCards
  },
  data() {
    return {};
  }
};
</script>
